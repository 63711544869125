import React, { useState, useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveNotificationCount } from "../../redux-store/activeNotifications/activeNotificationSlice";
import NotiLoader from "../loading/NotiLoader";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const AllNotifications = () => {
  const [showAll, setShowAll] = useState(false);
  const [notifications, setNotifications] = useState([]);
  console.log(notifications, "notifications");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const activeNotificationCount = useSelector(
    (state) => state.activeNotifications.activeNotificationCount
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=100&offset=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const notificationsData = response.data.content.data;
        const filteredNotifications = notificationsData.filter(
          (notification) => notification.sender_user.id !== userId
        );

        setNotifications(filteredNotifications);
        setLoading(false);

        // Calculate and set the number of active notifications
        const activeCount = filteredNotifications.filter(
          (notification) => notification.is_active === 1
        ).length;
        dispatch(setActiveNotificationCount(activeCount));
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    const monthsDiff = now.diff(createdAt, "months");
    const daysDiff = now.diff(createdAt, "days");
    const hoursDiff = now.diff(createdAt, "hours");
    const minutesDiff = now.diff(createdAt, "minutes");
    const secondsDiff = now.diff(createdAt, "seconds");

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNotificationClick = async (notification) => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      if (notification.title === "Bookmarked your profile") {
        navigate(`/user/${notification.by_user}`);
      } else {
        localStorage.setItem("notichannelId", notification.channel_id);
        navigate("/messages");
      }
      const response = await axios.put(
        `${baseUrl}/api/v1/customer/notification/read/${notification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      // Update local state to reflect the read status immediately
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, is_active: 0 } : n
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  const maxCharacters = 50;
  if (loading) {
    return (
      <div>
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
        <NotiLoader />
      </div>
    );
  }
  const filteredNotifications = notifications.filter(
    (notification) => notification.description !== null
  );
  return (
    <div className="">
      <ul role="list" className="divide-y divide-gray-100">
        {filteredNotifications.map((notification) => (
          <li
            key={notification.id}
            className={`flex w-full justify-between gap-x-6 py-5 hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer z-40 md:px-11 lg:px-11 px-3 ${
              notification.is_active === 0 ? "bg-white" : "bg-gray-100"
            }`}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="flex min-w-0 gap-x-4 items-start">
              {notification.sender_user.profile_image !== "default.png" ? (
                <Link
                  to={`/user/${notification.sender_user.id}`}
                  className="z-50 h-12 w-12 flex-shrink-0" // Prevents the image from shrinking or stretching
                >
                  <img
                    className={`w-12 h-12 rounded-full object-cover ${
                      notification.sender_user.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${notification.sender_user.profile_image}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-gray-300 flex-shrink-0 ${
                    notification.sender_user.type === "employer"
                      ? "ring-2 ring-[#E7831D] rounded-full"
                      : "ring-2 ring-gray-700 rounded-full"
                  }`}
                />
              )}

              <div className="min-w-0 flex-auto ml-4">
                <Link to={`/user/${notification.sender_user.id}`}>
                  <p className="text-md font-semibold leading-6 text-gray-900 cursor-pointer">
                    {notification.sender_user.first_name}{" "}
                    {notification.sender_user.last_name}
                  </p>
                </Link>

                <p
                  className={`text-xs flex items-center ${
                    notification.sender_user.type === "job-seeker"
                      ? "text-black"
                      : "text-[#E7831D]"
                  }`}
                >
                  {capitalizeFirstLetter(notification.sender_user.type)}
                  <IoIosCheckmarkCircle className="ml-1" />
                </p>

                {notification.description && (
                  <div className="mt-4 flex flex-col text-sm">
                    {notification.description
                      .slice(0, 180)
                      .split("\n")
                      .map((line, index) => (
                        <div key={index}>
                          {line.split(" ").map((word, idx) => (
                            <React.Fragment key={idx}>
                              {word.startsWith("#") ? (
                                <span className="text-[#E7831D] font-bold">
                                  {word}
                                </span>
                              ) : (
                                <span>{word}</span>
                              )}
                              {idx < line.split(" ").length - 1 && " "}
                            </React.Fragment>
                          ))}
                          <br />
                        </div>
                      ))}
                    {notification.description.length > 180 && "..."}
                  </div>
                )}
              </div>
            </div>

            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="mt-1 text-xs leading-5 text-gray-500">
                <p>{getTimeAgo(notification.created_at)}</p>
              </p>
            </div>
          </li>
        ))}
        <hr />
      </ul>
    </div>
  );
};

export default AllNotifications;
