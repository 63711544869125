import React, { useEffect, useState, useCallback, useRef } from "react";
import { PiNumberZeroBold } from "react-icons/pi";
import Posts from "../homepage/Posts";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Loading from "../loading/Loading";
import PostShimmer from "../loading/PostShimmer";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { FaSadTear } from "react-icons/fa";
import translations from "../../languages";
import UserList from "../mini-components/UserList";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import Explore from "../Explore";
const ExploreMain = ({ setSidebarOpen }) => {
  const [postdatas, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [totalPosts, setTotalPosts] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [postLimit, setPostLimit] = useState(10);
  const lastApiCallTime = useRef(0);
  const [filteredPostCount, setFilteredPostCount] = useState(0);
  console.log(filteredPostCount, "filteredPostCount");

  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const fetchPosts = useCallback(
    async (reset = false) => {
      const currentTime = Date.now();
      if (currentTime - lastApiCallTime.current < 500) {
        return;
      }
      lastApiCallTime.current = currentTime;

      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem("token");
        let url, params;

        const tagParams = {};
        activeTags.forEach((tag) => {
          switch (tag) {
            case "job_seeker_post":
              tagParams.job_seeker_post = 1;
              break;
            case "emp_profile":
              tagParams.emp_profile = 1;
              break;
            case "job_seeker_profile":
              tagParams.job_seeker_profile = 1;
              break;
            case "emp_post":
              tagParams.emp_post = 1;
              break;
            default:
              break;
          }
        });

        if (token) {
          url = `${baseURL}/api/v1/user/post/list`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        } else {
          url = `${baseURL}/api/v1/user/post/listoffline`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        }

        const response = await axios.get(url, {
          params,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const postDataStructure = response.data.content.data;
        const total = response.data.content.total;

        setTotalPosts(total);
        if (reset) {
          setPosts(postDataStructure);
          if (Object.keys(tagParams).length > 0 || searchText.trim() !== "") {
            setFilteredPostCount(postDataStructure.length);
          } else {
            setFilteredPostCount(0);
          }
        } else {
          setPosts((prev) => {
            const newPosts = postDataStructure.filter(
              (post) =>
                !prev.some((existingPost) => existingPost.id === post.id)
            );
            return [...prev, ...newPosts];
          });
        }

        setLoading(false);
        setFetching(false);
      } catch (err) {
        setLoading(false);
        setFetching(false);
      }
    },
    [postLimit, searchText, activeTags]
  );

  const debouncedFetchPosts = useCallback(
    debounce((reset) => {
      fetchPosts(reset);
    }, 300),
    [fetchPosts]
  );

  const handleInfiniteScroll = async () => {
    if (totalPosts && postdatas.length >= totalPosts) {
      return; //! Stop fetching more posts if we have already fetched all posts
    }

    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (!fetching) {
          setFetching(true);
          setPostLimit((prev) => prev + 10);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  useEffect(() => {
    fetchPosts(true);
  }, [postLimit, fetchPosts]);

  useEffect(() => {
    if (searchText.length === 0) {
      setPostLimit(10);
      debouncedFetchPosts(true);
    } else if (searchText.length >= 3) {
      setPostLimit(100);
      debouncedFetchPosts(true);
    }
  }, [searchText, debouncedFetchPosts]);

  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("User bookmarked successfully");
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast.error("Error bookmarking user. Please try again later.");
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post reported successfully");
    } catch (error) {
      console.error("Error reporting post:", error);
      toast.error("Error reporting post. Please try again later.");
    }
  };

  return (
    <div className="scrollbar-hide w-auto paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
        {texts.explore}
      </h1>
      {filteredPostCount > 0 && (
        <p className=" italic text-gray-600 px-8 text-xs">
          {filteredPostCount > 0 ? `${filteredPostCount} results found` : ""}
        </p>
      )}
      <div class="exploreDiv">
        <Explore />
      </div>

      <hr />
      <UserList />
      {loading && !fetching ? (
        <div className="flex flex-col">
          <PostShimmer />
          <PostShimmer />
          <PostShimmer />
        </div>
      ) : postdatas.length === 0 && !fetching ? (
        <div className="flex flex-col items-center justify-center mt-20">
          <PiNumberZeroBold size={70} className="text-[#E7831D] mb-4" />
          <p className="text-xl text-gray-600">Post available</p>
        </div>
      ) : (
        <Posts
          postdatas={postdatas}
          handleBookmark={handleBookmark}
          handleReportPost={handleReportPost}
        />
      )}

      {fetching && <Loading />}
    </div>
  );
};

export default ExploreMain;
