import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setUserSearchText,
  clearUserSearchText,
} from "../../redux-store/userSearch/userSearchSlice";
import AllUserList from "./AllUserList";
import translations from "../../languages";

const UserList = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;

  const userSearchText = useSelector(
    (state) => state.userSearch.userSearchText
  );
  const [searchResults, setSearchResults] = useState([]);
  console.log("searchResults", searchResults);

  useEffect(() => {
    if (userSearchText.length > 2) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/explorer_user_profile_list?`,
            {
              params: {
                limit: 100,
                offset: 1,
                job_seeker_profile: 1,
                emp_profile: 1,
                string: userSearchText,
              },
            }
          );
          setSearchResults(response.data.content.data);
        } catch (error) {
          console.error("Error fetching user search results:", error);
        }
      };

      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [userSearchText]);

  return (
    <div>
      {searchResults.length > 0 && (
        <div>
          {" "}
          <h1 className="text-lg font-bold mb-2 px-8 mt-5">
            {" "}
            {/* {texts.users} */}
          </h1>{" "}
          <AllUserList data={searchResults} />{" "}
        </div>
      )}
    </div>
  );
};

export default UserList;
