import React, { useState, useEffect } from "react";
import SearchMessage from "./SearchMessage";
import ChatComponent from "./ChatComponent";
import ChatList from "./ChatList";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AllMessages = (props) => {
  const { messagedata, options, OpenChat, chatOpened } = props;
  const navigate = useNavigate();

  const [selectedChat, setSelectedChat] = useState(null);
  const [chatlistData, setChatlistData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getChatList = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const currentRoute = window.location.pathname;
    const isBookmarkRoute = currentRoute.includes("bookmarks"); // Adjust this based on your route structure

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const bookmarkValue = isBookmarkRoute ? "yes" : "no";
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/channel-list`,
        {
          params: {
            limit: "100",
            offset: 1,
            bookmark: bookmarkValue,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setChatlistData(response.data.content.data);
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };

  const deleteChats = async (channelId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/delete-channel/${channelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getChatList();
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };
  const pinToTheTopChats = async (channelId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/pin-to-top`,
        { channel_id: channelId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getChatList();
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatList();
  }, []);

  const openChat = (chatData) => {
    setSelectedChat([chatData]);

    OpenChat(true);
  };
  return (
    <div className="w-full h-screen  ">
      {!chatOpened && (
        <div>
          <hr />
          <ChatList
            messagedata={chatlistData}
            openChat={openChat}
            loading={loading}
            deleteChats={deleteChats}
            pinToTheTopChats={pinToTheTopChats}
          />
        </div>
      )}
      <div>
        {chatOpened && (
          <ChatComponent chatData={selectedChat} OpenChat={OpenChat} />
        )}
      </div>
    </div>
  );
};

export default AllMessages;
