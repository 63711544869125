import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Modal, Button, Input } from "antd";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import bblogo from "../../assets/276pxlogo.svg";
import Postscopy from "../homepage/Postscopy";
import PostShimmer from "../loading/PostShimmer";
import logo from "../../assets/mainlogonew.png";
import { IoClose } from "react-icons/io5";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Toaster, toast } from "sonner";
const SharedPost = () => {
  const { id } = useParams(); // Get post ID from URL
  const [post, setPost] = useState(null); // Initialize post as null
  const navigate = useNavigate();
  const [loadingPost, setLoadingPost] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    fetchPost();
  }, [id]);

  const fetchPost = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPost(response.data.content); // Assuming the API returns a single post
      setLoadingPost(false);
    } catch (error) {
      console.error("Error fetching post:", error);
      setLoadingPost(false);
    }
  };

  return (
    <div className="w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            // Ensure setSidebarOpen function is defined or remove it
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" alt="Logo" />
        </div>
      </div>

      <hr />
      <div className="w-full p-5 ">
        <IoIosArrowRoundBack
          className="text-3xl cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      {loadingPost ? (
        <div className="">
          <PostShimmer />
        </div>
      ) : (
        // Pass the single post to Postscopy
        <Postscopy
          postdatas={[post]} // Wrap post in an array to match Postscopy's expected prop type
          fetchData={fetchPost}
          setLoadingpost={setLoadingPost}
        />
      )}
    </div>
  );
};

export default SharedPost;
