import React, { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import ProfileBanner from "./ProfileBanner";
import Postscopy from "../components/homepage/Posts";
import postdata from "./postData";
import { fetchUserDetails } from "../apis";
import MainLogoLoader from "../components/loading/MainLogoLoader";
import axios from "axios"; // Import Axios
import ProfileShimmer from "../components/loading/ProfileShimmer";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import bblogo from "../assets/276pxlogo.svg";
import mobilebarsvg from "../assets/barsvgmobtab.svg";
import { useNavigate } from "react-router-dom";
import PostShimmer from "../components/loading/PostShimmer";
const ProfileMain = ({ setSidebarOpen }) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingpost, setLoadingpost] = useState(true);
  const [userId, setUserId] = useState("");
  const searchText = useSelector((state) => state.search.searchText);
  const [postList, setPostList] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const fetchUserData = async () => {
    try {
      const userDetailsData = await fetchUserDetails();
      setUserDetails(userDetailsData.content);
      setUserId(userDetailsData.content.id);
      fetchData();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    setLoadingpost(true);
    try {
      const token = localStorage.getItem("token");
      const iduser = localStorage.getItem("userId");
      const postListResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/list?limit=100&offset=1&user_id=${iduser}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPostList(postListResponse.data.content.data);
      setLoadingpost(false);
    } catch (error) {
      console.error("Error fetching user details and post list:", error);
      setLoadingpost(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const updateProfileImage = async (profileImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("profile_image", profileImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  const updateBannerImage = async (bannerImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("banner_image", bannerImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // If the update is successful, fetch user data again to display the latest details
      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating banner image:", error);
    }
  };
  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);

  return (
    <div className="scrollbar-hide w-full paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <div className="flex ">
        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {texts.myProfile}
        </h1>
        <BsThreeDots className="ml-auto text-3xl mr-2" />
      </div>

      <hr />
      {loading ? (
        <ProfileShimmer />
      ) : (
        <ProfileBanner
          userDetails={userDetails}
          updateProfileImage={updateProfileImage}
          updateBannerImage={updateBannerImage}
        />
      )}
      <hr />
      {loadingpost ? (
        <PostShimmer />
      ) : (
        <Postscopy
          postdatas={postList}
          fetchData={fetchData}
          setLoadingpost={setLoadingpost}
        />
      )}
    </div>
  );
};

export default ProfileMain;
