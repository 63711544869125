import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setActiveNotificationCount } from "../../redux-store/activeNotifications/activeNotificationSlice";
import NotificationCards from "./NotificationCards";
import { IoCaretUpOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import NotificationShimmer from "./NotificationShimmer";

import translations from "../../languages";

const Notification = ({ list, height }) => {
  const [showAll, setShowAll] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  const action = translations[selectedLanguage].actions;
  const activeNotificationCount = useSelector(
    (state) => state.activeNotifications.activeNotificationCount
  );
  const dispatch = useDispatch();

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=100&offset=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const notificationsData = response.data.content.data;

        // Filter out notifications where sender_user.id matches userId
        const filteredNotifications = notificationsData.filter(
          (notification) => notification.sender_user.id !== userId
        );

        setNotifications(filteredNotifications);
        setLoading(false);

        // Calculate and set the number of active notifications
        const activeCount = filteredNotifications.filter(
          (notification) => notification.is_active === 1
        ).length;
        dispatch(setActiveNotificationCount(activeCount));
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  if (loading) {
    return <NotificationShimmer />;
  }
  return (
    <div
      className={`p-0 px-5 flex flex-col  bg-transparent overflow-y-auto notification-container z-30 mt-2`}
      style={{ maxHeight: height }}
    >
      {notifications.length !== 0 && (
        <>
          {/* <hr /> */}
          <span className="text-lg font-bold text-black mb-1 h-10 flex justify-between items-center">
            {texts.notifications}
            {showAll && (
              <>
                {/* <IoCaretUpOutline
                  className="text-2xl cursor-pointer text-orange-600/60"
                  onClick={toggleShowAll}
                  data-tooltip-id="collapseTooltip"
                  data-tooltip-content="Collapse"
                /> */}
                <span
                  className="text-sm text-[#E7831D] cursor-pointer hover:text-[#e98e33]"
                  onClick={toggleShowAll}
                >
                  {action.Showless}
                </span>
                <Tooltip id="collapseTooltip" place="top" effect="solid" />
              </>
            )}
          </span>
          <div className=" flex-1 overflow-auto p-0 m-0 mb-44 scrollbar-hide z-30">
            {notifications.length > 0 ? (
              <NotificationCards
                notifications={notifications}
                list={list}
                showAll={showAll}
                setShowAll={setShowAll}
                toggleShowAll={toggleShowAll}
              />
            ) : (
              <div className="flex items-center justify-center w-full"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
