import { FaChevronRight } from "react-icons/fa6";
import logo from "../assets/logoBig.png";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { Link, useParams } from "react-router-dom";

const TermandconditionSettings = () => {
  const { name } = useParams();
  const [termsContent, setTermsContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTermsContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://bocaenboca.goodcoderz.com/api/v1/admin/business-settings/get-pages-setup?page_name=${name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setTermsContent(response.data.content.value);
      } catch (error) {
        console.error("Error fetching terms:", error);
        setLoading(false);
      }
    };

    fetchTermsContent();
  }, []);

  const formatPolicyName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col justify-between">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <img
          className="mx-auto h-14 w-auto md:h-16 lg:h-20"
          src={logo}
          alt="Your Company"
        />
        <div className="mx-auto mt-10 max-w-2xl text-center">
          <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-gray-700 sm:text-4xl">
            {formatPolicyName(name)}
          </h1>

          {loading ? (
            <div className="flex flex-col w-full gap-5 mt-5 text-center justify-center items-center">
              <p className="w-full h-4 rounded-full bg-gray-300 animate-pulse"></p>
              <p className="w-11/12 h-4 rounded-full bg-gray-300 animate-pulse"></p>
              <p className="w-9/12 h-4 rounded-full bg-gray-300 animate-pulse"></p>
              <p className="w-11/12 h-4 rounded-full bg-gray-300 animate-pulse"></p>
              <p className="w-8/12 h-4 rounded-full bg-gray-300 animate-pulse"></p>
            </div>
          ) : (
            <div className="mt-6 text-left text-base leading-7 text-gray-700 sm:text-lg sm:leading-8">
              <div dangerouslySetInnerHTML={{ __html: termsContent }} />
            </div>
          )}
        </div>

        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <div className="mt-10 flex justify-center">
            <Link
              to="/settings"
              className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-500 transition ease-in-out duration-150"
            >
              <span aria-hidden="true" className="mr-1">
                &larr;
              </span>
              Back to settings
            </Link>
          </div>
        </div>
      </main>

      <footer className="bg-white border-t border-gray-200 py-4">
        <div className="text-center text-xs text-gray-500">
          <a
            href="https://www.bocaenboca.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-700 "
          >
            Boca En Boca is a trademark of The Peddler Group, Inc. Copyright
            2022.
          </a>
        </div>
      </footer>
    </div>
  );
};

export default TermandconditionSettings;
