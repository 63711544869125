// Login.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthFormHeading from "../components/mini-components/AuthFormHeading";
import RememberMeCheckbox from "../components/mini-components/RememberMeCheckbox";
import WelcomeBack from "../components/mini-components/WelcomeBack";
import SocialLogin from "../components/mini-components/SocialLogin";
import { loginUser } from "../apis";
import { Toaster, toast } from "sonner";
import { FaRegEye } from "react-icons/fa";
import { PiEyeClosedDuotone } from "react-icons/pi";
import { PiEyeClosed } from "react-icons/pi";
import { MdRemoveRedEye } from "react-icons/md";
import axios from "axios";
import HampsterLoader from "../components/loading/HampsterLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin, Modal } from "antd";
import ForgetPasswordModal from "./ForgetPasswordModal";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import translations from "../languages";
const Login = () => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].loginPage;
  const [formData, setFormData] = useState({
    email_or_phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email_or_phone: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  console.log(isModalVisible, "isModalVisible");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    const usernameRegex = /^[a-zA-Z0-9]{3,15}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,35}$/;
    const isEmailValid = emailRegex.test(formData.email_or_phone);
    const isPhoneValid = phoneRegex.test(formData.email_or_phone);
    const isUsernameValid = usernameRegex.test(formData.email_or_phone);

    const newErrors = {
      email_or_phone: !formData.email_or_phone
        ? "Email, phone number, or username is required"
        : !isEmailValid && !isPhoneValid && !isUsernameValid
        ? "Invalid email, phone number, or username format"
        : "",
      password: !formData.password
        ? "Password is required"
        : formData.password.length < 8
        ? "Password is too weak."
        : !passwordRegex.test(formData.password)
        ? "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        : "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleLogin = async () => {
    setLoadingLogin(true);
    if (!validateForm()) {
      toast.error("Please correct the errors in the form");
      setLoadingLogin(false);
      return;
    }

    try {
      const response = await loginUser(formData);

      if (response.response_code === "auth_login_401") {
        // Show the error message from the response
        toast.error(response?.message);
        setLoadingLogin(false);
        return;
      }

      const token = response?.content?.token;
      localStorage.setItem("token", token);

      navigate("/9974590");

      setTimeout(() => {
        navigate("/");
      }, 2000);
      setLoadingLogin(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoadingLogin(false);
    }
  };

  const handleForgetPassword = () => {
    // setIsModalVisible(true);
    navigate("/email-fp");
  };

  return (
    <>
      <div className="flex justify-center items-center w-full h-screen overflow-y-auto bg-white pt-5 pb-5">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/30 bg-opacity-50 z-50">
            <HampsterLoader />
          </div>
        )}
        <div className="bg-gray-50 lg:h-auto md:h-screen h-screen lg:w-4/12 md:w-full w-full rounded-[2rem] p-10 lg:px-11 lg:py-5 md:p-20 shadow-xl flex text-center flex-col">
          <div className="flex w-full">
            <IoArrowBackCircleOutline
              className="text-3xl text-black  justify-start items-start text-left cursor-pointer hover:scale-110  relative top-6"
              onClick={() => navigate("/")}
            />
          </div>
          <AuthFormHeading
            heading={texts.welcome}
            headingtwo={texts.welcomeBack}
          />

          <input
            type="text"
            name="email_or_phone"
            value={formData.email_or_phone}
            onChange={handleInputChange}
            className="bg-[#F2F2F2] p-3 rounded-full mb-2 border-2 border-[#818181] outline-none mt-3"
            placeholder={texts.emailPhoneOrUsername}
          />
          {errors.email_or_phone && (
            <p className="text-red-500 text-xs text-left ml-5">
              {errors.email_or_phone}
            </p>
          )}
          <div className="bg-[#F2F2F2] rounded-full mb-2 border-2 border-[#818181] outline-none mt-3 w-full flex justify-between items-center px-2">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder={texts.enterPassword}
              className="w-10/12 h-full py-3 px-1 bg-transparent outline-none"
            />
            {showPassword ? (
              <MdRemoveRedEye
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <PiEyeClosed
                className="text-gray-700 text-2xl justify-center items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          {errors.password && (
            <p className="text-red-500 text-xs text-left ml-5">
              {errors.password}
            </p>
          )}
          <div className="flex w-full">
            <RememberMeCheckbox text={texts.rememberMe} />
            <div
              className="text-sm text-[#E7831D] ml-auto items-center text-center p-2 mt-1 cursor-pointer z-50"
              onClick={handleForgetPassword}
            >
              {texts.forgotPassword}
            </div>
          </div>

          <button
            className="bg-[#404041] p-3 rounded-full mb-2 mt-3 text-white hover:bg-gray-500 hover:scale-95 transition-transform ease-in-out duration-300"
            onClick={handleLogin}
          >
            {loadingLogin && (
              <Space className="mr-3">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#fff" }}
                      spin
                    />
                  }
                  size="medium"
                />
              </Space>
            )}
            {texts.logIn}
          </button>
          <WelcomeBack text={texts.orSignInWith} />
          <SocialLogin />
          <div className="flex space-x-2 mt-5 justify-center items-center  mx-auto text-[#818181] text-sm w-full">
            <div className="text-[#3f3e3e] text-sm w-2/3">
              {" "}
              {texts.noAccount}
            </div>
          </div>

          <Link
            className="bg-[#404041] p-3 rounded-full  mt-3 text-white hover:bg-gray-500 hover:scale-95 transition-transform ease-in-out duration-300"
            to={"/signup"}
          >
            {texts.signUpForFree}
          </Link>
        </div>

        <ForgetPasswordModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
      <p className="  h-10 fixed bottom-0  mt-3 flex justify-center items-center  space-x-2  w-full   z-50 text-md text-gray-400 text-[.6rem]">
        {" "}
        <span
          className="  cursor-pointer"
          onClick={() => navigate("/policy/terms_and_conditions")}
        >
          {" "}
          Terms And Conditions{" "}
        </span>{" "}
        <span
          className=" cursor-pointer "
          onClick={() => navigate("/policy/privacy_policy")}
        >
          {" "}
          Privacy Policy{" "}
        </span>{" "}
      </p>
    </>
  );
};

export default Login;
