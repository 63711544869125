import React from "react";
import { Link } from "react-router-dom";

const Bottomnavigation = ({
  sidebarOpen,
  setSidebarOpen,
  logo,
  navigate,
  navigation,
  handleNavigationClick,
  activeNotificationCount,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  const filteredNavigation = navigation.filter((item) =>
    [
      "Home",
      "Explore",
      "Messages",
      "Bookmarks",
      "Notifications",
      "Notifications",
      "My Profile",
    ].includes(item.name)
  );

  return (
    <div className="fixed bottom-0 z-50 w-full bg-white border-t border-gray-200 shadow-md bottom-navigation">
      <ul className="flex justify-around p-2">
        {filteredNavigation.map((item) => (
          <li key={item.name} className="flex">
            <Link
              to={item.href}
              onClick={() => handleNavigationClick(item.name)}
              className={classNames(
                item.current
                  ? "text-[#E7831D] relative"
                  : "text-[#404041] hover:text-[#E7831D]",
                "group flex flex-col items-center gap-y-1 p-2 text-lg font-light"
              )}
            >
              {item.name === "Notifications" && activeNotificationCount > 0 && (
                <div className="relative flex items-center justify-center">
                  <span className="absolute z-40 w-5 h-5 bg-[#E7831D] rounded-full animate-spin"></span>
                  <span className="absolute z-50 flex items-center justify-center w-5 h-5 text-sm text-white rounded-full">
                    {activeNotificationCount}
                  </span>
                </div>
              )}
              <SvgIcon
                src={item.current ? item.iconActive : item.icon}
                className="h-6 w-6 shrink-0 group-hover:hidden"
                aria-hidden="true"
              />
              <SvgIcon
                src={item.iconActive}
                className="h-6 w-6 shrink-0 hidden group-hover:block"
                aria-hidden="true"
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Bottomnavigation;
